import * as TYPES from './types'

export default {
  setFeedback ({ commit }, obj) {
    commit(TYPES.SET_FEEDBACK, obj)
  },

  removeFeedback ({ commit }, key) {
    commit(TYPES.REMOVE_FEEDBACK, key)
  },

  setHeaderBack ({ commit }, obj) {
    commit(TYPES.SET_HEADER_BACK, obj)
  },

  removeHeaderBack ({ commit }) {
    commit(TYPES.REMOVE_HEADER_BACK)
  },

  setMenuOpen ({ commit }) {
    commit(TYPES.SET_MENU_TOGGLE)
  },
}
