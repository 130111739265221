<script>
import { mapState, mapActions } from 'vuex'
import Action from '@/components/Action'
import Icon from '@/components/Icon'

export default {
  name: 'Toast',

  components: {
    Action,
    Icon,
  },

  data () {
    return {
      timeout: 6000,
    }
  },

  computed: {
    ...mapState(['feedbacks']),
  },

  watch: {
    feedbacks () {
      if (this.feedbacks.length) {
        const feedback = this.feedbacks[this.feedbacks.length - 1]
        if (feedback.timer === null && feedback.type !== 'prompt') {
          feedback.timer = setTimeout(() => {
            this.removeFeedback(feedback.key)
          }, this.timeout)
        }
      }
    },
  },

  methods: {
    ...mapActions([
      'removeFeedback'
    ]),

    close (key) {
      this.removeFeedback(key)
    },

    agreed (action, key) {
      action.call()
      this.removeFeedback(key)
    },
  },
}
</script>

<template>
  <div
    v-if="feedbacks.length"
    :class="$classes['toast-container']"
    style="z-index: 2147483647 !important"
>
    <transition-group
      name="toast"
      :move-class="$classes['toast-move']"
      :enter-class="$classes['toast-enter']"
      :enter-active-class="$classes['toast-enter-active']"
      :leave-to-class="$classes['toast-leave-to']"
      :leave-active-class="$classes['toast-leave-active']"
      tag="div"
    >
      <template v-for="(feedback) in feedbacks">
        <div
          :key="feedback.key"
          :class="[$classes['toast'], {
            [$classes['toast-error']]: feedback.type === 'error',
            [$classes['toast-success']]: feedback.type === 'success',
          }]"
        >
          <h5
            v-if="feedback.title"
            :class="$classes['toast-title']"
          >
            {{ feedback.title }}
          </h5>
          <div
            v-if="feedback.message"
            :class="$classes['toast-message']"
          >
            <p v-html="feedback.message" />
          </div>
          <action
            :class="$classes['btn-close']"
            @click="close(feedback.key)"
          >
            <icon name="times" />
          </action>
        </div>
      </template>
    </transition-group>
  </div>
</template>

<style module>
.toast-container {
  position: fixed;
  width: 460px;
  right: 0;
  bottom: 50px;
  overflow: hidden;
}

.toast {
  padding: 25px 75px 25px 55px;
  border-radius: 5px;
  color: rgba(255,255,255,0.8);
  width: 430px;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.toast + .toast {
  margin-top: 20px;
}

.toast-success {
  background: #4A94D4;
}

.toast-error {
  background: #EC535E;
}

.toast-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  letter-spacing: 0.1em;
}

.toast-message p {
  font-size: 14px;
  margin-bottom: 0;
  letter-spacing: 0.1em;
}

.toast .btn-close {
  position: absolute;
  top: 25px;
  right: 30px;
  color: rgba(255,255,255,0.8);
}

/* ANIMATION */

.toast-move {
  transition: all 0.5s;
}

.toast-enter-active,
.toast-leave-active {
  opacity: 1;
  transform: none;
  transition: all 0.5s;
}

.toast-enter,
.toast-leave-to {
  transform: translate3d(100%, 0, 0);
  opacity: 0
}
</style>
