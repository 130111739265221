import tooltipCss from '@/assets/styles/tooltip.module.css'

const options = {
  defaultClass: tooltipCss['tooltip'],
  defaultTemplate: `<div class="tooltip" role="tooltip"><div class="tooltip-arrow ${tooltipCss['tooltip-arrow']}"></div><div class="tooltip-inner ${tooltipCss['tooltip-inner']}"></div></div>`,
  popover: {
    defaultClass: tooltipCss['popover'],
  },
}

export default options
