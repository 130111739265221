import store from '@/store'
import API from '@/services/api'
const Login = () => import('@/views/auth/components/Login')
const RecoverPassword = () => import('@/views/auth/components/RecoverPassword')
const ChangePassword = () => import('@/views/auth/components/ChangePassword')

export default [
  {
    name: 'auth.login',
    path: '/login',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: 'auth.recover.password',
    path: '/recuperar-senha',
    component: RecoverPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: 'auth.change.password',
    path: '/alterar-senha',
    component: ChangePassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: 'auth.logout',
    path: '/logout',
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      API.unsetToken()
      store.dispatch('auth/logout')
      API.deleteLocalStorage()
      next({ name: 'auth.login' })
    },
  }
]
