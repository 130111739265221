<script>
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import Dropdown from '@/components/Dropdown'
import { mapGetters } from 'vuex'

export default {
  name: 'Profile',

  computed: {
    ...mapGetters('auth', ['getUserName', 'getToken']),
  },

  components: {
    Action,
    Icon,
    Dropdown,
  },

  methods: {
    updateUsername () {
      this.$store.dispatch('updateUserName')
    },

    toggleDropdown () {
      this.$refs.dropdown.$emit('click')
    },

    getDropdownIcon (dropdownProps) {
      return dropdownProps.open ? 'caret-up' : 'caret-down'
    },
  },

}
</script>

<template>
  <dropdown
    ref="dropdown"
    right
  >
    <action
      slot="button"
      slot-scope="props"
      variant="flat"
      color="secondary"
      @click="toggleDropdown"
    >
      <span v-if="!$media.mobile"> {{ getUserName }} </span>
      <icon v-else name="user-circle" />
      <span :class="$classes['profile-btn-icon-wrapper']">
        <icon
          :name="getDropdownIcon(props)"
          :class="$classes['profile-btn-icon']"
        />
      </span>
    </action>
    <!-- Fim do dropdown -->
    <template slot="links">
      <!-- BTN TROCAR SENHA -->
      <action
        variant="flat"
        color="secondary"
        :to="{ name: 'profile.password' }"
        :class="$classes['dropdown-link']"
        @click="toggleDropdown"
      >
        <span :class="$classes['dropdown-link-icon-wrapper']">
          <icon name="key" :class="$classes['dropdown-link-icon']" />
        </span>
        Alterar senha
      </action>
      <!-- BTN CONFIG-->
      <action
        variant="flat"
        color="secondary"
        :to="{ name: 'profile.configPrice' }"
        :class="$classes['dropdown-link']"
        @click="toggleDropdown"
      >
        <span :class="$classes['dropdown-link-icon-wrapper']">
          <icon name="settings" />
        </span>
        Configurações
      </action>
      <!-- BTN LOGOUT -->
      <action
        variant="flat"
        color="secondary"
        :to="{ name: 'auth.logout' }"
        :class="$classes['dropdown-link']"
        @click="toggleDropdown"
      >
        <span :class="$classes['dropdown-link-icon-wrapper']">
          <icon name="sign-out-alt" :class="$classes['dropdown-link-icon']" />
        </span>
        Sair
      </action>
    </template>
  </dropdown>
</template>

<style module>
.profile-btn-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  margin-left: 5px;
}

.dropdown-link {
  display: flex;
  font-size: 14px;
  color: rgba(41,41,41,1.0);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  justify-content: flex-start;
  height: 50px;
  padding: 0 10px;
  white-space: nowrap;
}

.dropdown-link-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  margin-right: 5px;
}

.dropdown-link-icon {
  width: 20px;
  height: 20px;
}

@media (max-width: 767px) {
  .profile-btn-icon-wrapper {
    width: 20px;
    height: 20px;
  }

  .profile-btn-icon {
    width: 20px;
    height: 20px;
  }
}
</style>
