<script>
import { mapState, mapActions } from 'vuex'
import Profile from '@/components/Profile'
import Action from '@/components/Action'
import Icon from '@/components/Icon'

export default {
  name: 'HeaderBar',

  components: {
    Action,
    Icon,
    Profile,
  },

  computed: {
    ...mapState(['headerBack', 'menuOpenMobile']),

    getCaretIcon () {
      return this.menuOpenMobile ? 'caret-up' : 'caret-down'
    },
  },

  methods: {
    ...mapActions(['setMenuOpen']),
  },
}
</script>

<template>
  <header :class="$classes['header']">
    <action
      v-if="$media.mobile"
      variant="flat"
      color="secondary"
      @click="setMenuOpen"
    >
      <icon name="menu" />
    </action>
    <div :class="$classes['logo-wrapper']">
      <action
        :to="{ name: 'home' }"
      >
        <img src="@/assets/images/logo.png" alt="" :class="$classes['logo']">
      </action>
    </div>
    <div :class="$classes['header-content']">
      <div :class="$classes['header-back']" v-if="!$media.mobile">
        <action
          v-if="headerBack"
          variant="flat"
          color="secondary"
          uppercase
          :to="headerBack"
        >
          <icon name="long-arrow-alt-left" btnIcon/>
          Voltar
        </action>
      </div>
      <profile />
    </div>
  </header>
</template>

<style module>
.header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  height: 85px;
  background: #fff;
  padding: 0 25px;
  z-index: 91;
}

.header-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 60px;
}

.logo {
  width: 155px;
  height: auto;
}

@media (max-width: 767px) {
  .header {
    height: 80px;
  }

  .logo-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    width: 130px;
  }

  .header-content {
    flex-grow: unset;
    padding-left: 0;
  }
}
</style>
