const Orders = () => import('@/views/orders/components/Orders')
const OrdersDetails = () => import('@/views/orders/components/OrdersDetails')

export default [
  {
    name: 'orders',
    path: '/pedidos',
    component: Orders,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'orders.details',
    path: '/pedidos/:id',
    component: OrdersDetails,
    meta: {
      requiresAuth: true,
    },
  }
]
