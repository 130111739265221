/* eslint-disable no-console */
import logger from '@/logger/index'
import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      logger.debug(
        'App is being served from cache by a service worker.\n'
      )
    },
    registered () {
      logger.debug('Service worker has been registered.')
    },
    cached () {
      logger.debug('Content has been cached for offline use.')
    },
    updatefound () {
      logger.debug('New content is downloading.')
    },
    updated () {
      logger.debug('New content is available; please refresh.')
    },
    offline () {
      logger.debug('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      logger.error('Error during service worker registration:', error)
    },
  })
}
