<script>
export default {
  name: 'Icon',

  props: {
    name: {
      type: String,
      default: null,
    },

    btnIcon: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses () {
      return {
        [this.$classes['icon']]: true,
        [this.$classes['btn-icon']]: this.btnIcon,
      }
    },

    iconSvg () {
      if (!this.name) return
      return require(`@/assets/icons/${this.name}.svg?raw`)
    },
  },
}
</script>

<template>
  <component
    :is="iconSvg"
    :class="computedClasses"
  />
</template>

<style module>
.icon {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.btn-icon {
  margin-right: 15px;
}
</style>
