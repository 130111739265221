const NotFound = () => import('@/views/notFound/components/NotFound')

export default [
  {
    name: 'catchall',
    path: '*',
    redirect: { name: 'notFound' },
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: 'notFound',
    path: '/404',
    component: NotFound,
    meta: {
      requiresAuth: false,
    },
  }
]
