import API from '@/services/api'
import store from '@/store'
import { logsService } from '@/services'
import {
  GET_PLATE,
  GET_SEARCH_SUGGESTIONS
} from '@/services/enpoints'

const apiPlate = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: GET_PLATE,
    data,
  }).catch(error => {
    logsService.logs({ error: error })
    throw error
  })
}

const searchSuggestions = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: GET_SEARCH_SUGGESTIONS,
    data,
  }).catch(error => {
    logsService.logs({ error: error })
    throw error
  })
}

export const vehicleService = {
  apiPlate,
  searchSuggestions,
}
