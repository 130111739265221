<script>
import Loading from '@/components/Loading'

export default {
  name: 'Action',

  components: {
    Loading,
  },

  props: {
    type: {
      type: String,
      default: 'button',
    },

    url: {
      type: String,
      default: null,
    },

    target: {
      type: String,
      default: null,
    },

    to: {
      type: Object,
      default: null,
    },

    flat: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    download: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    fullWidth: {
      type: Boolean,
      default: false,
    },

    mobile: {
      type: Boolean,
      default: false,
    },

    uppercase: {
      type: Boolean,
      default: false,
    },

    bold: {
      type: Boolean,
      default: false,
    },

    variant: {
      type: String,
      default: null,
    },

    activeClass: {
      type: String,
      default: null,
    },
  },

  computed: {
    computedClasses () {
      return {
        [this.$classes['btn-contained']]: this.variant === 'contained',
        [this.$classes['btn-outlined']]: this.variant === 'outlined',
        [this.$classes['btn-flat']]: this.variant === 'flat',
        [this.$classes['has-color-primary']]: this.color === 'primary',
        [this.$classes['has-color-secondary']]: this.color === 'secondary',
        [this.$classes['has-color-tertiary']]: this.color === 'tertiary',
        [this.$classes['is-full-width']]: this.fullWidth,
        [this.$classes['is-disabled']]: this.disabled,
        [this.$classes['is-uppercase']]: this.uppercase,
        [this.$classes['is-bold']]: this.bold,
        [this.$classes['is-mobile']]: this.mobile,
      }
    },

    componentType () {
      if (this.url) {
        return 'link'
      }
      if (this.to) {
        return 'link-router'
      }
      return 'button'
    },

    hasSlot () {
      return !!this.$slots.default
    },
  },

  methods: {
    triggerClickEvent (event) {
      this.$emit('click', event)
    },
  },
}
</script>

<template>
  <button v-if="componentType === 'button'" :class="[$classes['btn'], computedClasses]" :disabled="disabled" :type="type"
    @click="triggerClickEvent">
    <slot />
    <loading v-if="loading" :class="{
      [$classes['loading']]: true,
      [$classes['has-slot']]: hasSlot
    }" />
  </button>
  <a v-else-if="componentType === 'link'" :class="[$classes['btn'], computedClasses]" :href="url" :target="target"
    :download="download">
    <slot />
    <loading v-if="loading" :class="{
      [$classes['loading']]: true,
      [$classes['has-slot']]: hasSlot
    }" />
  </a>
  <router-link v-else-if="componentType === 'link-router'" :class="[$classes['btn'], computedClasses]" :to="to"
    :active-class="activeClass" @click.native="triggerClickEvent">
    <slot />
    <loading v-if="loading" :class="{
      [$classes['loading']]: true,
      [$classes['has-slot']]: hasSlot
    }" />
  </router-link>
</template>

<style module>
.btn {
  display: inline-flex;
  align-items: center;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  background: none;
  text-decoration: none;
  justify-content: center;
  transition: background 0.2s, color 0.2s;
}

.btn.is-disabled {
  cursor: not-allowed;
}

.btn.is-uppercase {
  text-transform: uppercase;
}

.btn.is-bold {
  font-weight: 600;
}

.btn.is-full-width {
  display: flex;
  width: 100%;
}

.btn .loading {
  width: 20px;
  height: 20px;
}

.btn .loading.has-slot {
  margin-left: 10px;
}

/* BTN CONTAINED */

.btn-contained {
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  border-radius: 2px;
  padding: 0 30px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.btn-contained.has-color-primary {
  color: #fff;
  background-color: #4A94D4;
}

.btn-contained.has-color-primary:hover {
  background: #5CC0DE;
}

.btn-contained.has-color-primary.is-disabled {
  background: #DBDBDB;
}

.btn-contained.has-color-secondary {
  background-color: #D14040;
  color: #fff;
}

.btn-contained.has-color-secondary:hover {
  background-color: #d14040a6;
}

.btn-contained.has-color-secondary.is-disabled {
  background-color: #DBDBDB;
  color: #fff;
}

/* BTN OUTLINED */

.btn-outlined {
  height: 40px;
  font-size: 14px;
  letter-spacing: 0.1em;
  font-weight: normal;
  border-radius: 2px;
  padding: 0 20px;
  line-height: 18px;
  text-transform: uppercase;
  border: 1px solid;
}

.btn-outlined.is-mobile {
  border-radius: 5px;
  padding: 0 5px;
}

.btn-outlined.has-color-primary {
  color: #aaa;
  border-color: #aaa;
}

.btn-outlined.has-color-primary:hover {
  color: #292929;
  border-color: #292929;
}

.btn-outlined.has-color-primary.is-disabled {
  color: #DBDBDB;
  border-color: #DBDBDB;
}

.btn-outlined.has-color-secondary {
  color: #292929;
  border-color: #292929;
}

.btn-outlined.has-color-secondary:hover {
  background: #292929;
  color: #fff;
}

.btn-outlined.has-color-secondary.is-disabled {
  border-color: #DBDBDB;
  color: #DBDBDB;
}

.btn-outlined.has-color-tertiary {
  color: #4A94D4;
  border-color: #4A94D4;
}

.btn-outlined.has-color-tertiary:hover {
  background: #4A94D4;
  color: #fff;
}

.btn-outlined.has-color-tertiary.is-disabled {
  border-color: #DBDBDB;
  color: #DBDBDB;
}

/* BTN FLAT */

.btn-flat {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.1em;
}

.btn-flat.has-color-primary {
  color: #4A94D4;
}

.btn-flat.has-color-primary:hover {
  color: #5CC0DE;
}

.btn-flat.has-color-primary.is-disabled {
  color: #DBDBDB;
}

.btn-flat.has-color-secondary {
  color: #A2A2A2;
}

.btn-flat.has-color-secondary:hover {
  color: #292929;
}

.btn-flat.has-color-secondary.is-disabled {
  color: #DBDBDB;
}

.btn-flat.has-color-tertiary {
  color: #D14040;
}

.btn-flat.has-color-tertiary:hover {
  color: #5CC0DE;
}

.btn-flat.has-color-tertiary.is-disabled {
  color: #DBDBDB;
}
</style>
