<script>
export default {
  name: 'Dropdown',

  props: {
    right: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isOpen: false,
    }
  },

  created () {
    this.$on('click', function () {
      this.toggleMenu()
    })
  },

  methods: {
    toggleMenu () {
      this.isOpen = !this.isOpen
    },

    outside () {
      this.isOpen = false
    },

    openDropdown () {
      this.toggleMenu()
    },
  },
}
</script>

<template>
  <div
    v-click-outside="outside"
    :class="[$classes['dropdown'], {
      [$classes['is-open']]: isOpen
    }]"
  >
    <slot
      name="button"
      :open="isOpen"
    />
    <transition name="fadeInUp">
      <div
        v-if="isOpen"
        :class="[$classes['dropdown-content'], {
          [$classes['open-right']]: right
        }]"
      >
        <slot name="links" />
      </div>
    </transition>
  </div>
</template>

<style module>
.dropdown {
  position: relative;
  display: inline-flex;
}

.dropdown-content {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(79, 79, 79, 0.25);
  z-index: 10;
}

.dropdown-content.open-right {
  left: auto;
  right: 0;
}

/* ANIMATION */

.dropdown .fadeInUp-enter-active,
.dropdown .fadeInUp-leave-active {
  opacity: 1;
  transform: none;
  transition: opacity 0.2s, transform 0.2s;
}

.dropdown .fadeInUp-enter,
.dropdown .fadeInUp-leave-to {
  transform: translate3d(0, 10%, 0);
  opacity: 0
}
</style>
