import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import Vuelidate from 'vuelidate'
import vClickOutside from 'v-click-outside'
import VueCurrencyFilter from 'vue-currency-filter'
import VTooltip from 'v-tooltip'
import VueMq from 'vue-mq'
import { mixin } from './mixins/index'
import options from '@/utils/tooltip'
import './registerServiceWorker'
import VueImageZoomer from 'vue-image-zoomer'
import VCalendar from 'v-calendar'

Vue.use(VCalendar)
Vue.use(VueImageZoomer)
Vue.use(Vuelidate)
Vue.use(vClickOutside)
Vue.use(VueCurrencyFilter, {
  symbol: 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
})
Vue.use(VTooltip, options)
Vue.use(VueMq, {
  breakpoints: {
    mobile: 768,
    tablet: 1281,
    desktop: Infinity,
  },
})
Vue.mixin(mixin)

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
  Vue.config.devtools = false
  Vue.config.debug = false
  Vue.config.silent = true
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
