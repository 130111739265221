import store from '@/store'
const Products = () => import('@/views/products/components/Products')

export default [
  {
    name: 'products',
    path: '/produtos',
    component: Products,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'parts',
    path: '/produtos/modal',
    component: Products,
    meta: {
      requiresAuth: true,
      openModal: true,
    },

    beforeEnter: (to, from, next) => {
      const isEnabledParts = store.state.userPermissions.isEnabledParts

      if (!isEnabledParts) {
        next({ name: 'products' })
      }
      next()
    },
  }
]
