import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import state from './state'
import mutations from './mutations'
import actions from './actions'
import modules from './modules'
import API from '@/services/api'
Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
  reducer: ({ auth }) => {
    const encryptedText = API.encrypt(JSON.stringify({ auth }))
    return encryptedText
  },
})

export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  state,
  mutations,
  actions,
  modules,
})
