<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Action from '@/components/Action'
import Icon from '@/components/Icon'
// import logger from '@/logger/index'

export default {
  name: 'MenuNav',

  components: {
    Action,
    Icon,
  },

  data () {
    return {
      isExpanded: true,
      showSubmenu: false,
    }
  },

  computed: {
    ...mapState(['menuOpenMobile']),
    ...mapGetters('auth', ['getUserName', 'getToken', 'getCNPJ']),
    ...mapGetters('userPermissions', ['getEnableParts']),

    menu () {
      return [
        {
          text: 'Produtos',
          icon: 'search',
          link: { name: 'products' },
          url: null,
          submenu: [
            {
              text: 'Conj. Peças',
              link: { name: 'parts' },
              icon: 'sub-arrow-right',
            }
          ],
        },
        {
          text: 'Pedidos',
          icon: 'clipboard-list',
          link: { name: 'orders' },
          url: null,
        },
        {
          text: 'Financeiro',
          icon: 'file-invoice-dollar',
          link: null,
          url: 'https://www2.scherer-sa.com.br/api/financeiro-login-token/' + this.getToken,
        }
      ]
    },

    computedClasses () {
      return {
        [this.$classes['menu']]: true,
        [this.$classes['is-expanded']]: this.isExpanded,
        [this.$classes['is-mobile']]: this.$media.mobile,
        [this.$classes['is-open']]: this.menuOpenMobile,
      }
    },

    expandedIcon () {
      return this.isExpanded ? 'chevron-left' : 'chevron-right'
    },

    expandedText () {
      return this.isExpanded ? 'Recolher' : ''
    },

    expandedIconSubMenu () {
      return this.showSubmenu ? 'caret-up' : 'caret-down'
    },
  },

  watch: {
    $media () {
      this.isExpanded = this.$media.desktop
      if (this.$media.mobile) {
        this.isExpanded = true
      }
    },
    $route () {
      if (this.$route.meta.openModal) {
        this.showSubmenu = !this.showSubmenu
      }
    },
  },

  mounted () {
    this.getPermissions({
      cnpj: this.getCNPJ,
    })
  },

  methods: {
    ...mapActions(['setMenuOpen']),
    ...mapActions('userPermissions', ['getPermissions']),

    handleExpand () {
      this.isExpanded = !this.isExpanded
      if (this.isExpanded === false) {
        this.showSubmenu = false
      }
    },

    handleOpenLink (value) {
      if (this.$media.mobile && !this.showSubmenu) {
        this.setMenuOpen()
      }

      if (value.name !== 'products') {
        this.showSubmenu = false
      }
    },
    handleShowSubMenu () {
      this.showSubmenu = !this.showSubmenu
    },
  },
}
</script>

<template>
  <aside :class="computedClasses">
    <div :class="$classes['menu-div']">
      <nav :class="$classes['menu-nav']">
        <template v-if="$media.mobile">
          <action :class="$classes['menu-close']" @click="handleOpenLink">
            <icon name="times" :class="$classes['menu-close-icon']" />
          </action>
          <div :class="$classes['user']">
            <icon name="user-circle" :class="$classes['user-icon']" />
            <span :class="$classes['user-name']">{{ getUserName }}</span>
          </div>
        </template>
        <template v-for="(menuItem, index) in menu">
          <action :key="index" :to="menuItem.link" :url="menuItem.url" :target="menuItem.url ? 'blank' : null"
            :class="{ [$classes['menu-nav-link']]: true, [$classes['is-expanded']]: isExpanded }"
            :active-class="$classes['is-active']" @click="handleOpenLink(menuItem.link)">
            <span :class="$classes['menu-nav-icon-wrapper']">
              <icon :name="menuItem.icon" :class="$classes['menu-nav-icon']" />
            </span>
            <div v-if="isExpanded" :class="$classes['menu-div-content']">
              <span :class="$classes['menu-nav-text']">{{ menuItem.text }}</span>
              <span v-if="menuItem.submenu && getEnableParts"
                :class="[$classes['menu-nav-icon-arrow'], $classes['arrow-down']]" @click.prevent="handleShowSubMenu">
                <icon :name="expandedIconSubMenu" :class="$classes['menu-close-icon']" />
              </span>
            </div>
            <ul v-if="showSubmenu && getEnableParts && isExpanded">
              <li v-for="   submenuItem    in    menuItem.submenu   " :key="submenuItem.text">
                <action :to="submenuItem.link" :class="$classes['menu-nav-link']" :active-class="$classes['is-active']">
                  <span :class="$classes['submenu-nav-icon-wrapper']">
                    <icon :name="submenuItem.icon" :class="$classes['menu-nav-icon']" />
                  </span>
                  <span>{{ submenuItem.text }}</span>
                </action>
              </li>
            </ul>
          </action>
        </template>
        <action v-if="$media.mobile" :to="{ name: 'auth.logout' }" :class="$classes['menu-nav-link']">
          <span :class="$classes['menu-nav-icon-wrapper']">
            <icon name="sign-out-alt" :class="$classes['menu-nav-icon']" />
          </span>
          <span :class="$classes['menu-nav-text']">Sair da conta</span>
        </action>
        <action v-if="!$media.mobile"
          :class="{ [$classes['menu-nav-link']]: true, [$classes['is-expanded']]: isExpanded }"
          :active-class="$classes['is-active']" @click="handleExpand(0)">
          <span :class="$classes['menu-nav-icon-wrapper']">
            <icon :name="expandedIcon" />
          </span>
          <div v-if="isExpanded" :class="$classes['menu-div-content']">
            <span :class="$classes['menu-nav-text']">{{ expandedText }}</span>
          </div>
        </action>
      </nav>
    </div>
  </aside>
</template>

<style module>
.menu {
  background: #292929;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: hidden;
  padding: 20px 0;
  transition: width 0.2s;
  width: 70px;
}

.menu.is-expanded {
  width: 210px;
  justify-content: flex-start;
}

.menu-div {
  position: fixed;
  left: 14px;
  width: auto;
  max-width: 210px;
}

.menu-nav {
  width: auto;
  margin: 0 auto;
  justify-content: center;
}

.menu-div-content {
  display: contents;
}

.menu-nav-link {
  justify-content: inherit;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  flex-shrink: 0;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  height: auto;
  color: #a1a1a1;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 5px;
  white-space: nowrap;
  transition: color 0.2s;
}

.menu-nav-link.is-expanded {
  justify-content: flex-start;
}

.menu-nav-link ul {
  padding-left: 45px;
  margin-bottom: 0;
  list-style: none;
  width: 100%;
}

.menu-nav-link ul li a {
  font-size: 12px;
  align-items: center;
}

.menu-nav-link:hover,
.menu-nav-link.is-active {
  color: #D14040;
}

.menu-nav-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  padding: 3px
}

.menu-nav-icon-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  padding: 3px
}

.menu-nav-icon-arrow.arrow-down:hover {
  background-color: #a1a1a127;
  border-radius: 5px;
  width: 30px;
  height: 30px;
}

.submenu-nav-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 32px;
  flex-shrink: 0;
}

.submenu-nav-icon-wrapper .menu-nav-icon {
  height: 18px;
  width: 18px;
}

.menu-nav-text {
  margin-left: 15px;
  margin-right: 10px;
}

/* MOBILE */
@media (max-width: 767px) {
  .menu.is-mobile {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    z-index: 99;
    transform: translateX(-100%);
    transition: transform 0.2s;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);
    padding-top: 105px;
  }

  .menu.is-open {
    transform: translateX(0);
  }

  .menu-div {
    position: fixed;
    left: 14px;
    width: auto;
    display: contents;
    max-width: 210px;
  }

  .menu-nav {
    width: 230px;
    margin: 0 auto;
  }

  .menu-close {
    position: absolute;
    top: 30px;
    left: 20px;
    color: rgba(255, 255, 255, 0.54);
  }

  .menu-close-icon {
    width: 20px;
    height: 20px;
  }

  .user {
    width: 230px;
    margin-bottom: 25px;
  }

  .user-icon {
    width: 66px;
    height: 66px;
    color: #fff;
  }

  .user-name {
    display: block;
    margin-top: 24px;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.1em;
  }

  .menu-nav-link {
    font-size: 18px;
    padding-left: 0;
  }

  .menu-nav-link ul li a {
    font-size: 14px;
    align-items: center;
  }

  .menu-nav-icon-wrapper .menu-nav-icon {
    height: 20px;
    width: 20px;
  }

  .menu-nav-text {
    margin-left: 20px;
  }
}
</style>
