import { routes as auth } from './auth'
import { routes as notFound } from './notFound'
import { routes as home } from './home'
import { routes as products } from './products'
import { routes as orders } from './orders'

export default [
  ...auth,
  ...notFound,
  ...home,
  ...products,
  ...orders
]
