import store from '@/store'
import API from '@/services/api'

const beforeEach = (to, from, next) => {
  const hexRegex = /^[0-9a-fA-F]+$/
  if (localStorage.getItem('vuex')) {
    const vuexData = JSON.parse(localStorage.getItem('vuex')) ?? ''
    if (hexRegex.test(vuexData)) {
      const decryptedText = API.decrypt(vuexData)
      let { auth } = JSON.parse(decryptedText)
      if (auth) {
        store.state.auth.cnpj = auth.cnpj
        store.state.auth.token = auth.token
        store.state.auth.userName = auth.userName
      }
    } else {
      store.dispatch('auth/logout')
      next({ name: 'auth.login' })
    }
  }

  const requiresAuth = to.meta.requiresAuth === true
  const isAuthenticated = !!store.state.auth.token

  if (to.name === '404') {
    next()
  }
  if (requiresAuth && !isAuthenticated) {
    next({ name: 'auth.login' })
  }
  if (!requiresAuth && isAuthenticated) {
    next({ name: 'home' })
  }
  next()
}

export default beforeEach
