import * as TYPES from './types'
import shortid from 'shortid'

export default {
  [TYPES.SET_FEEDBACK] (state, obj) {
    state.feedbacks = [
      ...state.feedbacks,
      {
        key: shortid.generate(),
        timer: null,
        ...obj,
      }
    ]
  },

  [TYPES.REMOVE_FEEDBACK] (state, key) {
    state.feedbacks = state.feedbacks.filter(feedback => feedback.key !== key)
  },

  [TYPES.SET_HEADER_BACK] (state, url) {
    state.headerBack = url
  },

  [TYPES.REMOVE_HEADER_BACK] (state) {
    state.headerBack = null
  },

  [TYPES.SET_MENU_TOGGLE] (state) {
    state.menuOpenMobile = !state.menuOpenMobile
  },
}
