import { format, parseISO } from 'date-fns'

export const mixin = {
  props: {
    classes: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      DATE_FORMAT: 'dd/MM/yyyy',
      $classes: {},
    }
  },

  computed: {
    $media () {
      return {
        mobile: this.$mq === 'mobile',
        tablet: this.$mq === 'tablet',
        desktop: this.$mq === 'desktop',
      }
    },
  },

  watch: {
    $style () {
      this.createClasses()
    },
  },

  methods: {
    createClasses () {
      this.$classes = this.$style || {}
      if (this.classes) {
        Object.keys(this.classes).map(key => {
          if (this.$classes[key]) {
            this.$classes[key] = `${this.$classes[key]} ${this.classes[key]}`
          }
        })
      }
    },

    formatDate (date, formatString = this.DATE_FORMAT) {
      return format(parseISO(date), formatString)
    },
  },

  created () {
    this.createClasses()
  },
}
