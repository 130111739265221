const Home = () => import('@/views/home/components/Home')
const ChangeProfilePassword = () => import('@/views/home/components/ChangeProfilePassword')
const ChangeProfilePriceAdd = () => import('@/views/home/components/ChangeProfilePriceAdd')

export default [
  {
    name: 'home',
    path: '/',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'profile.password',
    path: '/alterar-senha-perfil',
    component: ChangeProfilePassword,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'profile.configPrice',
    path: '/atualizar-percentual',
    component: ChangeProfilePriceAdd,
    meta: {
      requiresAuth: true,
    },
  }
]
