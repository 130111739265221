<script>
export default {
  name: 'Loading',
}
</script>

<template>
  <svg
    :class="$classes['loading']"
    viewBox="25 25 50 50"
  >
    <circle
      :class="$classes['path']"
      cx="50"
      cy="50"
      r="20"
      fill="none"
      stroke-width="4"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<style module>
.loading {
  width: 20px;
  height: auto;
  animation: rotate 2s linear infinite;
  transform-origin: center center;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: currentColor;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

</style>
