<script>
import API from '@/services/api'
import { mapState } from 'vuex'
import HeaderBar from '@/components/HeaderBar'
import MenuNav from '@/components/MenuNav'
import Toast from '@/components/Toast'

export default {
  name: 'App',

  components: {
    HeaderBar,
    MenuNav,
    Toast,
  },

  computed: {
    ...mapState(['auth']),
  },

  created () {
    API.setToken(this.auth.token)
  },
}

</script>

<template>
  <div :class="$classes['app']">
    <header-bar v-if="auth.token" />
    <div :class="$classes['app-inner']">
      <menu-nav v-if="auth.token" />
      <div :class="$classes['app-content']">
        <router-view />
      </div>
    </div>
    <toast />
  </div>
</template>

<style module>
.app {}

.app-inner {
  display: -webkit-box;
  align-items: stretch;
  min-height: calc(100vh - 85px);
}

.app-content {
  flex: 1;
}

@media (max-width: 767px) {
  .app-inner {
    min-height: calc(100vh - 80px);
  }
}
</style>

<style>
* {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:focus {
  outline-color: transparent;
  outline-style: none;
}

::-moz-selection {
  background: #4A94D4;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #4A94D4;
  color: #fff;
  text-shadow: none;
}

/* FIX AUTOCOMPLETE */

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both;
}

@keyframes autofill {
  to {
    color: #292929;
    background: transparent;
  }
}

body {
  background: #F3F3F4;
  color: #292929;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 10px;

  scrollbar-width: thin;
  scrollbar-color: #a2a2a2 transparent;
}

body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #a2a2a2;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

button,
input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

ul,
ol {
  list-style: none;
}

p {
  font-size: 16px;
  margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
</style>
