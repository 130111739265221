import API from '@/services/api'
import store from '@/store'
import {
  SEND_LOGS
} from '@/services/enpoints'

const logs = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: SEND_LOGS,
    data,
  })
}

export const logsService = {
  logs,
}
