import userPermissions from './userPermissions'
import auth from './auth'
import orders from './orders'
import products from './products'
import vehicle from './vehicle'

export default {
  userPermissions,
  auth,
  orders,
  products,
  vehicle,
}
