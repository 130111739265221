export const LOGIN_URL = '/autentica'
export const RECOVER_PASSWORD = '/recuperar-senha'
export const CHANGE_PASSWORD = '/alterar-senha'
export const USER_PERMISSIONS = '/usuario-permissoes'
export const SEND_LOGS = '/api/logs'

export const GET_ORDER_CART = '/get-carrinhos'
export const GET_ORDERS = '/get-pedidos'
export const ADD_ORDER = '/inserir-carrinho'
export const ADD_PRODUCT_ORDER = '/inserir-produto-carrinho'
export const REMOVE_PRODUCT_ORDER = '/excluir-produto-carrinho'
export const UPDATE_PRODUCT_ORDER = '/atualizar-produto-carrinho'
export const CAN_SEND_ORDER = '/produtos/pode-enviar-pedido'
export const UPDATE_ORDER_NAME = '/atualizar-carrinho-descricao'
export const UPDATE_ORDER_OBS = '/atualizar-carrinho-observacao'
export const DELETE_ORDER = '/excluir-carrinho'
export const CHECKOUT_ORDER = '/enviar-pedido'

export const CHECK_PRODUCT_STOCK = '/produtos/estoque'
export const CHECK_PRODUCT_STOCK_ARRAY = '/produtos/estoque-array'
export const CHECK_PRODUCT_PRICE = '/preco-liquido'
export const SEARCH_PRODUCT = '/buscar-produtos'
export const GET_PRODUCT = '/produto-detalhes'
export const GET_PRICE_ADD = '/percentual'
export const UPDATE_PRICE_ADD = '/atualizar-percentual'

export const GET_PARTS = '/conjunto-pecas'
export const GET_PARTS_PRODUCT = '/conjunto-detalhes'
export const GET_PRODUCT_RELATED = '/produto-relacionados'

export const GET_PLATE = '/placa'
export const GET_SEARCH_SUGGESTIONS = '/placa/sugestao-busca'
