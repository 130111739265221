import API from '@/services/api'
import { authService, logsService } from '@/services'
// import areIntervalsOverlappingWithOptions from 'date-fns/esm/fp/areIntervalsOverlappingWithOptions/index.js'

const TYPES = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SET_USERNAME: 'SET_USERNAME',
  SET_CNPJ: 'SET_CNPJ',
}

const state = () => ({
  token: null,
  userName: 'default',
  cnpj: null,
})

const getters = {
  getUserName: (state) => {
    return state.userName
  },
  getToken: (state) => {
    return state.token
  },
  getCNPJ: (state) => {
    return state.cnpj
  },
}

const actions = {
  login ({ commit }, data) {
    return new Promise((resolve, reject) => {
      authService.login(data).then(({ data }) => {
        API.setToken(data.token)
        // CHAMA A MUTATION
        commit(TYPES.LOGIN, data)
        commit(TYPES.SET_USERNAME, data.name)
        commit(TYPES.SET_CNPJ, data)
        resolve(data)
      }).catch(({ response }) => {
        logsService.logs({ alert: response })
        response !== undefined ? reject(response.data) : reject(response)
      })
    })
  },

  logout ({ commit }) {
    commit(TYPES.LOGOUT)
  },

  recoverPassword (data) {
    return new Promise((resolve, reject) => {
      authService.recoverPassword(data).then(({ data }) => {
        resolve(data)
      }).catch(({ response }) => {
        logsService.logs({ alert: response })
        response !== undefined ? reject(response.data) : reject(response)
      })
    })
  },

  changePassword (data) {
    return new Promise((resolve, reject) => {
      authService.changePassword(data).then(({ data }) => {
        resolve(data)
      }).catch(({ response }) => {
        logsService.logs({ alert: response })
        response !== undefined ? reject(response.data) : reject(response)
      })
    })
  },
}

const mutations = {
  [TYPES.LOGIN] (state, payload) {
    state.token = payload.token
  },

  [TYPES.LOGOUT] (state) {
    state.cnpj = null
    state.token = null
    state.userName = null
  },

  [TYPES.SET_USERNAME] (state, payload) {
    // ALTERAR O VALOR DO STATE
    state.userName = payload
  },
  [TYPES.SET_CNPJ] (state, payload) {
    state.cnpj = payload.CNPJ
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
