import store from '@/store'
import API from '@/services/api'
import { logsService } from '@/services'
import {
  USER_PERMISSIONS
} from '@/services/enpoints'

const checkPermissions = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: USER_PERMISSIONS,
    data, // cnpj
  }).catch(error => {
    logsService.logs({ error: error })
    throw error
  })
}

export const permissionsService = {
  checkPermissions,
}
